import React from "react";
import PageComponent from "./PageComponent.jsx";

import {Link} from "react-router-dom";
import NewsBlock from "./NewsBlock.jsx";

function getQueryVariable(search) {
    var map = {};
    var query = search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        map[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    }
    return map;
}

export default class LocationDetail extends PageComponent {

    constructor(props) {
        super(props);

        this.pageCode = 'location-detail';

        const {province, zone} = props.match.params;

        this.hub = props.hub;

        this.basecCandidates = this.hub.candidates.filter(i => i.province === province && parseInt(i.zone) === parseInt(zone));
        this.candidates = this.basecCandidates;
        this.province = province;
        this.zone = zone;

        const query = getQueryVariable(this.props.location.search);
        const defaultFilter = '0,0,0';

        const filter = (query.filter || defaultFilter).split(',').map(i => Boolean(parseInt(i)));

        this.state = {
            filter: filter
        };
        this.rawFilter = query.filter;

        this.filterCandidates();
        this.filterTimeout = null;
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.hub.pushPath(this.pageCode);
        let data = {
            province: this.province,
            zone: this.zone
        };
        if (this.rawFilter) {
            data.filter = this.state.filter
        }
        this.hub.logStat(this.pageCode, 'location-detail-page', data);
    }

    filterCandidates() {
        const xnc = this.hub.xnc;
        const filter = this.state.filter;

        this.candidates = this.basecCandidates.filter(item => {
            const partyXnc = xnc[item.party.replace('พรรค', '')]; // x nc
            const partySnc = partyXnc && partyXnc.snc; // support nc
            const partyNnc = partyXnc && partyXnc.nnc; // not support nc

            if (partySnc) {
                item.badge = 'snc';
            } else if (partyNnc) {
                item.badge = 'nnc';
            } else {
                item.badge = 'xnc';
            }

            return (!filter[0] && !filter[1] && !filter[2]) || (filter[0] && partySnc) || (filter[1] && partyNnc) || (filter[2] && !partySnc && !partyNnc)
        });
    }

    toggleFilter(i) {
        let filter = this.state.filter;
        filter[i] = !filter[i];
        this.filterCandidates();

        this.props.history.push({
            search   : '?filter=' + this.state.filter.map(i => 0+i).join(',')
        });

        let self = this;

        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {

            self.hub.logStat(self.pageCode, 'location-detail-page', {
                province: self.province,
                zone: self.zone,
                filter: self.state.filter
            });

        }, 5000)
    }

    renderCandidateItem(item) {
        let key = `/candidates/${item.province}/${item.zone}/${item.no}`;

        let isTerminated = false;
        if (this.hub.terminates.indexOf(item.party) !== -1) {
            isTerminated = true;
        }
        return (
            <div key={key} className="candidate-item-wrapper col-xs-12 col-md-6 col-xl-4">
                <Link to={key} className={`candidate-item d-flex align-items-center${isTerminated ? ' terminated': ''}`}>
                    
                    <div className="candidate-item-right w-100">
                        <div className={`candidate-item-badge badge-${item.badge}`}>
                        </div>
                        <div className="candidate-item-left">
                            <h4>เบอร์</h4>
                            <h2>{item.no}</h2>
                        </div>
                        <div className="candidate-item-body">
                            <h3 className="candidate_title">
                                {item.party}
                                {isTerminated ? ' [ยุบพรรค]': ''}
                            </h3>
                            <h5 className="candidate_name">{item.prefix}{item.firstname} {item.lastname}</h5>
                        </div>
                        <div className="candidate-party-img-wrapper">
                            <div className="candidate-party-img text-white" style={{backgroundImage: `url(/static/images/logo-party/${item.party.replace('พรรค', '')}.jpg)`}}>
                        </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }

    render() {

        let xncButton;
        if (!this.hub.xncTransparent) {
            xncButton = (
                <button type="button" className={`btn btn-nc btn-xnc ${this.state.filter[2] ? 'active' : ''}`} onClick={() => { this.toggleFilter(2)}}>
                    <div className="checkbox-dummy">
                        {this.state.filter[2] ? <i className="fa fa-check-circle-o fa-lg"></i> : <i className="fa fa-circle-o fa-lg"></i>}
                    </div>
                    <div className="checkbox-label">จุดยืนไม่ชัด</div>
                </button>
            )
        }

        return (
            <div className="container">
                <Link to="/" className="btn btn-back btn-light lh0 d-print-none"><i className="fa fa-chevron-left fa-2"/></Link>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-8 mb-4">
                        <div className="location-top text-center">
                            <h3>{this.province} เขต {this.zone}</h3>
                            <h6>{this.hub.utils.getDeepLocation(this.province, this.zone)}</h6>
                        </div>
                    </div>
                </div>
                
                <div className="party-filter">
                    <h5 className="text-center">เลือกเฉพาะพรรคที่ :</h5>
                    <div className="btn-group text-center">
                        <button type="button" className={`btn btn-nc btn-snc ${this.state.filter[0] ? 'active' : ''}`} onClick={() => { this.toggleFilter(0)}}>
                            <div className={this.hub.xncTransparent? 'checkbox-dummy-inline': 'checkbox-dummy'}>
                                {this.state.filter[0] ? <i className="fa fa-check-circle-o fa"></i> : <i className="fa fa-circle-o fa"></i>}
                            </div>
                            <div className={this.hub.xncTransparent? 'checkbox-label -inline': 'checkbox-label'}>พรรคที่สนับสนุนคสช.</div>
                        </button>
                        <button type="button" className={`btn btn-nc btn-nnc ${this.state.filter[1] ? 'active' : ''}`} onClick={() => { this.toggleFilter(1)}}>
                            <div className={this.hub.xncTransparent? 'checkbox-dummy-inline': 'checkbox-dummy'}>
                                {this.state.filter[1] ? <i className="fa fa-check-circle-o fa"></i> : <i className="fa fa-circle-o fa"></i>}
                            </div>
                            <div className={this.hub.xncTransparent? 'checkbox-label -inline': 'checkbox-label'}>พรรคที่ไม่สนับสนุนคสช.</div>
                        </button>
                        {xncButton}
                    </div>
                </div>
                

                <div className="location-middle">
                    <div className="row">
                        {this.candidates.map(item => this.renderCandidateItem(item))}
                    </div>
                </div>


                <p className="text-muted text-center">ข้อมูลจุดยืนพรรค&nbsp;<i className="fa fa-check-circle-o color-snc"></i>&nbsp;<i className="fa fa-check-circle-o color-nnc"></i> จากการสัมภาษณ์ และนโยบายพรรค<br />ณ วันที่ 6 มีนาคม 2562 เวลา 12:28 น.</p>
                <div className="d-print-none">
                    <NewsBlock/>
                </div>
            </div>
        )
    }
}
