import React from "react";

import CandidateDetail from "./CandidateDetail.jsx";
import {Link} from "react-router-dom";

export default class CandidateImage extends CandidateDetail {

    constructor(props) {
        super(props);

        this.pageCode = 'candidate-image';
        this.hub = props.hub;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.hub.pushPath(this.pageCode);
        this.hub.logStat(this.pageCode, 'candidate-save', {
            candidate: this.candidate
        });
    }

    render() {
        const item = this.candidate;

        return (
            <div className="container">
                <Link to={`/candidates/${item.province}/${item.zone}/${item.no}`} className="btn btn-back btn-light lh0 d-print-none">
                    <i className="fa fa-chevron-left fa-2"/>
                </Link>
                <div className="text-center">
                    <br/>
                    <div className="alert alert-dark" style={{maxWidth: '630px', margin: '0 auto'}}>
                        <strong>! กรุณากดค้างที่รูปเพื่อบันทึกลงอัลบัมของคุณ</strong>
                    </div>
                    <br/>
                    <div className="shadow-lg">
                        <img src={`https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/images%2Fcard%2F${this.province}.${this.zone}.${this.no}.card.png?alt=media`} alt="x" className="img-fluid shadow" />
                    </div>
                </div>
            </div>
        )
    }
}