import React from "react";
import PageComponent from "./PageComponent.jsx";

export default class NoMatch extends PageComponent {
    render() {
        return (
            <div className="container _db-helvethaicaX wrapper-height100">
                <div className="text-center">
                    <h1>404</h1>
                    <p>ไม่พบหน้าที่คุณหา</p>
                </div>
            </div>
        )
    }
}