import React, {Component} from "react";

export default class Footer extends Component {
    render() {
        return (
            <div className="footer _pt-60">
                {/* <img src="/static/images/black-dot.png" alt="" className="image-bg-black"/>
                <nav className="container">
                    <div className="d-flex justify-content-center">
                        <div><a href="https://ilaw.or.th/">
                            <img src="/static/images/ilaw-logo.png" 
                                 srcSet="/static/images/ilaw-logo@2x.png 2x" 
                                 className="img-fluid" alt="ilaw" /></a>
                        </div>
                        <div className="ml-1 ml-md-3"><a href="https://themomentum.co/">
                            <img src="/static/images/themomentum-logo.png" 
                                 srcSet="/static/images/themomentum-logo@2x.png 2x" 
                                 className="img-fluid logo-themomentum" alt="The Momentum"/></a>
                        </div>
                        <div className="ml-1 ml-md-3"><a href="https://adaybulletin.com/">
                            <img src="/static/images/aday-bulletin-logo.png" 
                                 srcSet="/static/images/aday-bulletin-logo@2x.png 2x" 
                                 className="img-fluid" alt="aday bulletin" /></a>
                        </div>
                        <div className="ml-1 ml-md-3">
                            <img src="/static/images/opendream-logo.png" 
                                 srcSet="/static/images/opendream-logo@2x.png 2x" 
                                 className="img-fluid" alt="Opendream" /></div>
                    </div>
                </nav> */}
            </div>
        )
    }
}
