import React from "react";

import {Link} from "react-router-dom";

import PageComponent from "./PageComponent.jsx";
import CandidateMatrix from "./CandidateMatrix.jsx";

import Party from "../data/party.json";
import Autosuggest from 'react-autosuggest';
import {
    LineShareButton, LineIcon,
    FacebookShareButton, FacebookIcon,
    TwitterShareButton, TwitterIcon,
    EmailShareButton, EmailIcon
} from "react-share";
import * as axios from "axios";
import Papa from "papaparse";

const popularSort = (a, b) => {
    return (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0)
}

function numberWithCommas(x) {
    return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class Motion extends PageComponent {


    constructor(props) {
        super(props);

        this.hub = props.hub;
        this.animate = !this.hub.iOS && !this.hub.android; // TODO: detect frame rate instead
        this.totalPoseAnimated = 0;

        this.pageCode = 'motion';


        this.defaultDataSourceCode = 'ect100';
        this.realtimeUnsubscribe = null

        this.ectLabel = <span>ข้อมูลอย่างไม่เป็นทางการจาก API ของ กกต เมื่อนับคะแนนแล้ว 94%<br/>ในวันที่ 25 มีนาคม 2562</span>
        this.ect100Label = <span>ข้อมูลจาก กกต. เมื่อนับคะแนนแล้ว 100%<br/>ในวันที่ 28 มีนาคม 2562</span>

        this.state = {
            paradeMode: 'geoPartyList',
            dataSourceCode: this.defaultDataSourceCode,
            partyMPList: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
            selectedParty: {},

            // Location
            location: null,
            queryLocation: this.getLocationSuggestionValue(null),
            locationMatches: [],

            forceUpdate: false
        };

        this.urlUnlisten = null

        this.toggleMatrixMode = this.toggleMatrixMode.bind(this);
        this.renderCandidateMotion = this.renderCandidateMotion.bind(this);

        this.getDataSource = this.getDataSource.bind(this);
        this.getStatisticDataSource = this.getStatisticDataSource.bind(this);
        this.getCrowdDataSource = this.getCrowdDataSource.bind(this);
        this.getEctDataSource = this.getEctDataSource.bind(this);
        this.updateDataSource = this.updateDataSource.bind(this);
        this.onCandidateUnitPoseComplete = this.onCandidateUnitPoseComplete.bind(this);

        // Location
        this.searchLocation = this.searchLocation.bind(this);
        this.getLocationSuggestions = this.getLocationSuggestions.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onLocationSuggestionsFetchRequested = this.onLocationSuggestionsFetchRequested.bind(this);
        this.onLocationSuggestionsClearRequested = this.onLocationSuggestionsClearRequested.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
        this.onLocationSuggestionSelected = this.onLocationSuggestionSelected.bind(this);
        this.onLocationClear = this.onLocationClear.bind(this);
        this.getLocationSuggestionValue = this.getLocationSuggestionValue.bind(this);
        this.shouldRenderLocationSuggestions = this.shouldRenderLocationSuggestions.bind(this);
        this.renderLocationItem = this.renderLocationItem.bind(this);
        this.onPartyBoxClick = this.onPartyBoxClick.bind(this);

        // Stat
        this.seeAllScore = this.seeAllScore.bind(this);
        this.shareClick = this.shareClick.bind(this);
    }

    sourceURLToCode = source => {
        return {
            'ect100': 'ect100',
            'ect94': 'ect',
            'crowdsource': 'crowdsource'
        }[source] || 'ect100'
    }
    componentWillMount() {
        this.urlUnlisten = this.props.history.listen((location) => {
            const source = location.pathname.split('/')[2]
            const code = this.sourceURLToCode(source)
            setTimeout(() => this.hub.logStat(this.pageCode, `motion-select-data-source-${code}`))
            this.checkActiveSource(source)
        })
    }
    componentWillUnmount() {
        if (this.urlUnlisten) this.urlUnlisten()
    }

    checkActiveSource = source => {
        // check active tab from hash
        const code = this.sourceURLToCode(source)
        this.updateDataSource(code, null, true)
    }

    componentDidMount() {
        this.checkActiveSource(this.props.match.params.source)

        window.scrollTo(0, 0);
        this.hub.pushPath(this.pageCode);
        this.hub.logStat(this.pageCode, 'motion-page');
    }

    getDataSourcePath = (source) => {
        const appMode = this.hub.appMode

        let path = {
            'ect': `${appMode}--cache/ect-elect-results`,
            'crowdsource': `${appMode}--cache/elect-results--people`,
            'statistic': `production--cache/aggregate--candidate-detail-page`
        }

        return path[source]
    }

    async getDataSource(code) {
        code = code || this.state.dataSourceCode

        if (this.realtimeUnsubscribe) {
            this.realtimeUnsubscribe()
        }

        let resultsPromise
        if (code === 'statistic') {
            resultsPromise = this.getStatisticDataSource()
        } else if (code === 'crowdsource') {
            resultsPromise = this.getCrowdDataSource()
        } else if (code === 'ect') {
            resultsPromise = this.getEctDataSource()
        } else if (code === 'ect100') {
            resultsPromise = this.getEct100DataSource()
        } else {
            resultsPromise = []
        }

        let results = await resultsPromise
        const endpoint = this.getDataSourcePath(code)

        if (endpoint) {
            this.realtimeUnsubscribe = this.hub.db.doc(endpoint).onSnapshot(snapshot => {
                console.log('-> receive realtime data', code)
                if (snapshot.data()) {
                    this.updateDataSource(code, JSON.parse(snapshot.data().data || '{}'))
                }
            }, e => {
                console.log('-> realtime stream error', e)
            })
        }

        return results
    }

    getStatisticDataSource(random) {

        const rearrange = (item, key, ignore) => {

            key = key || 'party';

            let adjustTotal = 0;
            item[key].forEach(party => {

                // let newTotal = (party.total + 1) * 30;
                let newTotal = party.total;
                if (random) {
                    newTotal += Math.floor(Math.random()*100);
                }

                if (party.key === 'พรรคอนาคตใหม่') {
                    newTotal = Math.floor(newTotal/20);
                }
                else if (party.key === 'พรรคเศรษฐกิจใหม่') {
                    newTotal = Math.floor(newTotal/7);
                } else if (party.key === 'พรรคเสรีรวมไทย') {
                    newTotal = Math.floor(newTotal/4);
                } else if (party.key === 'พรรคพลังประชารัฐ') {
                    newTotal = Math.floor(newTotal*1.8);
                }
                
                if (this.hub.terminates.indexOf(party.key) !== -1) {
                    newTotal = 0;
                }

                adjustTotal += newTotal - party.total;
                party.total = newTotal;

            });

            if (!ignore) {
                item.total += adjustTotal;
            }

            return adjustTotal;

        };

        return this.hub.db.collection(`production--cache`).doc(`aggregate--candidate-detail-page`).get().then(doc => {
            // return {};
            let data = JSON.parse(doc.data().data || '{}');
            data.province.forEach(p => {
                p.zones.forEach(z => {
                    rearrange(z);
                    z.total = z.party.reduce((a, b) => a + b.total, 0);
                });
                rearrange(p);
                p.total = p.zones.reduce((a, b) => a + b.total, 0);
            });
            data.total = data.province.reduce((a, b) => a + b.total, 0);
            rearrange(data, 'popular', true);

            return data;
        })
    };

    async getCrowdDataSource() {
        try {
            let ref = await this.hub.db.doc(`${this.hub.appMode}--cache/elect-results--people`).get()
            if (ref.exists) {
                return JSON.parse(ref.data().data)
            } else {
                return {}
            }
        } catch (e) {
            console.error(e)
        }
    }

    async getEctDataSource() {
        try {
            let ref = await this.hub.db.doc(`${this.hub.appMode}--cache/ect-elect-results`).get()
            if (ref.exists) {

                let dataSource = JSON.parse(ref.data().data);
                dataSource.noVoteTotal = dataSource.noVoteTotal || 0
                dataSource.voidedTotal = dataSource.voidedTotal || 0
                return dataSource
            } else {
                return {}
            }
        } catch (e) {
            console.error(e)
        }
    }

    async getEct100DataSource() {
        let url = 'https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/data%2Fect100.csv?alt=media';
        let resp = await axios.get(url);
        let data = Papa.parse(resp.data, {header: true}).data;

        // console.log(data);

        let candidateMap = {};
        this.hub.candidates.forEach(c => {
            if (!candidateMap[c.province]) candidateMap[c.province] = {};
            if (!candidateMap[c.province][c.zone]) candidateMap[c.province][c.zone] = {};
            if (!candidateMap[c.province][c.zone][c.no]) candidateMap[c.province][c.zone][c.no] = c;
        });

        // console.log(this.hub.candidates)
        // console.log('data', data)
        let result = {province: [], popular: [], total: 0};
        let provinceMap = {}, provinceList = [];
        let popularMap = {}, popularList = [];

        let csv = '';

        data.forEach(row => {
            let province = row.province; // TODO: clean province
            // get candidate from this.hub.candidates map with row data
            let candidate = candidateMap[row.province][row.zone][row.no];
            province = candidate.province;

            let zone = candidate.zone;
            let party = candidate.party;

            if (!provinceMap[candidate.province]) provinceMap[province] = {key: province, total: 0, party: [], zones: {}};
            if (!provinceMap[candidate.province].zones[zone]) provinceMap[province].zones[zone] = {key: zone, total: 0, party: []};
            if (!popularMap[party]) popularMap[party] = {key: party, total: 0};

            let score = parseInt(row.score);

            result.total += score;

            provinceMap[province].zones[zone].total += score;
            provinceMap[province].zones[zone].party.push({
                key: candidate.party,
                total: score
            });

            popularMap[party].total += score;
        });


        Object.keys(provinceMap).map(function(provinceKey) {
            let province = provinceMap[provinceKey];
            let zoneList = [];
            Object.keys(province.zones).map(function(zoneKey) {
                let zone = provinceMap[provinceKey].zones[zoneKey];
                zoneList.push(zone);
            });
            province.zones = zoneList;
            provinceList.push(province);
        });
        result.province = provinceList;

        Object.keys(popularMap).map(function(popularKey) {
            let popular = popularMap[popularKey];
            popularList.push(popular);
        });
        result.popular = popularList;

        result.noVoteTotal = 605392;
        result.voidedTotal = 2130327;

        return result;


    }

    // updatePartyList(dataSource) {
    //     dataSource.province.forEach(p)
    // }

    async updateDataSource(code, preloadedDataSource, forceUpdate) {
        let dataSource;
        let cache = JSON.parse(localStorage.getItem(`cache-dataSource-${code || this.defaultDataSourceCode}`));
        let hit = cache && (Date.now() - parseInt(cache.timestamp)) < 1*60*1000;

        if (hit && !preloadedDataSource) { // TODO: get data source from cache every 1 minute (success) or when receive realtime message from firebase

            dataSource = cache.value;

        } else { // build data source

            let forceUpdate = Boolean(preloadedDataSource) || forceUpdate;

            this.setState({
                preparing: true,
                forceUpdate: forceUpdate
            });
            if (!preloadedDataSource) {
                dataSource = await this.getDataSource(code)
            } else {
                dataSource = preloadedDataSource
            }

            let partyMP = {};
            let partyScore = {};

            // Faster by index map and ordering

            if (dataSource.popular) {
                dataSource.popular.sort(popularSort);
                dataSource.popular.forEach((party) => {
                    if (party.key !== 'noVote' && party.key !== 'voided') {
                        partyScore[party.key] = party.total;
                    }
                });
            }


            dataSource.succeedTotal = 0;
            dataSource.noVoteTotal = dataSource.noVoteTotal || 0;
            dataSource.voidedTotal = dataSource.voidedTotal || 0;
            dataSource.goodVoteTotal = 0;


            if (dataSource.province) {
                dataSource.province = dataSource.province.reduce((obj1, province) => {

                    province.zones = province.zones.reduce((obj2, zone) => {
                        zone.party.sort(popularSort);

                        // provide score for party list
                        let winnerParty = null;
                        let noVote = null;
                        let voided = null;

                        let onlyParty = zone.party.filter((party, i) => {
                            if (party.key === 'noVote') {
                                noVote = party;
                            }
                            if (party.key === 'voided') {
                                voided = party;
                            }
                            return party.key !== 'noVote' && party.key !== 'voided';
                        });

                        if (onlyParty.length >= 1 && noVote && noVote.total >= onlyParty[0].total) {
                            // re vote
                        } else if (onlyParty.length >= 2 && onlyParty[0].total === onlyParty[1].total) {
                            // draw
                        } else if (onlyParty.length >= 1 && onlyParty.filter(p => p.total > 0).length >= 3) {
                            winnerParty = onlyParty[0];
                        }
                        zone.winnerParty = winnerParty;
                        zone.succeed = zone.party.filter(it => it.total > 0).length > 0

                        if (zone.succeed) {
                            dataSource.succeedTotal++;
                        }
                        if (noVote) {
                            dataSource.noVoteTotal += noVote.total;
                        }
                        if (voided) {
                            dataSource.voidedTotal += voided.total;
                        }
                        dataSource.goodVoteTotal += onlyParty.reduce((a, b) => a + b.total, 0);


                        if (winnerParty) {
                            if (!partyMP[winnerParty.key]) {
                                partyMP[winnerParty.key] = 0;
                            }
                            partyMP[winnerParty.key]++;
                        }

                        obj2[zone.key] = zone;

                        return obj2;
                    }, {});

                    province.party.sort(popularSort);
                    obj1[province.key] = province;

                    return obj1
                }, {});
            }

            // calculate party list

            const totalScore = Object.keys(partyScore).reduce((a, key) => a + partyScore[key], 0);
            const score4Rep = totalScore / 500;

            const calPartyList = () => {

                let partyShouldMP = [];

                for (const key of Object.keys(partyScore)) {

                    const zoneTotal = partyMP[key] || 0;
                    const score = partyScore[key];
                    const partyRep = score4Rep ? score / score4Rep : 0;
                    const partyRepCeilling = Math.floor(partyRep);
                    const partyListCount = Math.max(0, partyRepCeilling - zoneTotal);


                    const partyName = key.replace('พรรค', '');

                    partyShouldMP.push({
                        key: key,
                        color: Party[partyName].color,
                        shortName: Party[partyName].shortName,
                        total: partyRep,
                        partyListCount: partyListCount,
                        moreTotal: partyListCount,
                        zoneTotal: zoneTotal,
                        allTotal: zoneTotal + partyListCount,
                        score: score
                    });
                }
                partyShouldMP.sort(popularSort);

                return partyShouldMP;
            }

            let partyShouldMP = calPartyList();
            let sumPartyList = partyShouldMP.reduce((a, p) => a + p.partyListCount, 0);

            if (sumPartyList > 150) {
                let popularPartyListDecimal = [];
                let partyShouldMPMap = {};

                partyShouldMP.forEach(p => {
                    let partyListCount = p.partyListCount*150/sumPartyList;
                    let partyListDecimal = partyListCount - Math.floor(partyListCount);
                    p.partyListCount = Math.floor(partyListCount);
                    p.moreTotal = p.partyListCount; // alias
                    partyShouldMPMap[p.key] = p;
                    popularPartyListDecimal.push({key: p.key, total: partyListDecimal})
                });
                popularPartyListDecimal.sort(popularSort);

                let remainingMP = 150 - partyShouldMP.reduce((a, p) => a + p.partyListCount, 0);
                popularPartyListDecimal.forEach(p => {
                    if (remainingMP > 0) {
                        partyShouldMPMap[p.key].partyListCount++;
                        partyShouldMPMap[p.key].moreTotal = partyShouldMPMap[p.key].partyListCount;
                        remainingMP--;
                    }

                });


            } else {
                // TODO: increase MP by decimal
            }
            sumPartyList = partyShouldMP.reduce((a, p) => a + p.partyListCount, 0);


            let remainingMP = 150;
            partyShouldMP.forEach(party => {
                party.finalMoreTotal = Math.min(party.moreTotal, remainingMP);
                party.allTotal = party.zoneTotal + party.finalMoreTotal;
                remainingMP -= party.finalMoreTotal;
            });


            dataSource.partyShouldMP = partyShouldMP;

        }


        // serve and set state from cache or build data

        let partyMPList = [].concat(dataSource.partyShouldMP);
        partyMPList.sort((a,b) => (a.allTotal > b.allTotal) ? -1 : ((b.allTotal > a.allTotal) ? 1 : 0));

        let newState = {
            dataSource: dataSource,
            partyMPList: partyMPList,
            selectedParty: {},
            preparing: false,
            forceUpdate: false
        };
        if (code) {
            newState.dataSourceCode = code;
        }
        this.setState(newState);

        if (!hit || preloadedDataSource) {
            cache = JSON.stringify({value: dataSource, timestamp: Date.now()});
            localStorage.setItem(`cache-dataSource-${code || this.defaultDataSourceCode}`, cache);
        }
    }


    setParadeMode(mode) {
        this.setState({
            loading: true
        });

        const self = this;
        setTimeout(() => {
            let newState = {
                paradeMode: mode
            };
            if (!this.animate) {
                newState.loading = false;
            }
            self.setState(newState);
            
        }, 1000);

    }

    toggleMatrixMode() {
        if (this.state.loading) {
            return;
        }
        const newMode = this.state.paradeMode === 'geoPartyList'? 'council': 'geoPartyList';
        this.setParadeMode(newMode);
        this.hub.logStat(this.pageCode, 'motion-set-parade-mode-'+newMode);
    }

    onCandidateUnitPoseComplete() {

        if (this.animate) {
            this.totalPoseAnimated++;
            if (this.totalPoseAnimated >= 500) {
                this.setState({loading: false});
                this.totalPoseAnimated = 0;
            }
        }
    }

    // ===================================================
    // Location
    // ===================================================

    searchLocation(keywords, items, matches, limit, parents, root) {

        var self = this;

        items.forEach(item => {

            if (matches.length >= limit) {
                return false;
            }

            let label = item.postcode ? item.name + ':' + item.postcode: item.name;
            label = parents ? parents + '|' + label : label;

            let province = root? root: item.name;

            let isMatch = !keywords || keywords.filter(keyword => label.indexOf(keyword) !== -1).length >= keywords.length;

            if (item.children) {
                self.searchLocation(keywords, item.children, matches, limit, label, province);
            } else if (isMatch) {
                matches.push({
                    label: label,
                    id: item.id,
                    province: province,
                    zone: item.zone
                });
            }

        });
    }

    getLocationSuggestions(value) {
        const keywords = value.toLowerCase().replace(/ »/g, '').trim().split(' ');
        let matches = [];
        if (value.length >= 2) {
            this.searchLocation(keywords, this.hub.zones, matches, 10);
        }
        return matches;
    }


    onLocationChange(event, { newValue }) {
        this.setState({
            location: null,
            queryLocation: newValue
        });
    }

    onLocationSuggestionsFetchRequested({ value }) {
        this.setState({
            location: null,
            locationMatches: this.getLocationSuggestions(value)
        });
    }

    onLocationSuggestionsClearRequested() {
        this.setState({
            locationMatches: []
        });
    }

    onLocationSelected(location) {
        if (location) {
            this.setState({location: location});
            $(this.refs.candidateDistrictModal).modal('show');
            this.hub.logStat(this.pageCode, 'motion-on-location-select', location);
        }
    }

    onLocationSuggestionSelected(event, { suggestion }) {
        this.onLocationSelected(suggestion);
    }

    onLocationClear(event) {
        this.refs.locationAutosuggest.props.inputProps.value = '';
        this.refs.locationAutosuggest.input.value = '';
        this.refs.locationAutosuggest.input.focus();

        let value = '';
        this.setState({
            location: null,
            queryLocation: value,
            locationMatches: this.getLocationSuggestions(value)
        });
    }

    getLocationSuggestionValue(item) {
        if (!item) return '';
        const location = item.label.split('|').map(level => level.split(':')[0]);
        return location.join(' » ');
    }

    shouldRenderLocationSuggestions(value) {
        return true;
    }

    renderLocationItem(item) {
        return this.getLocationSuggestionValue(item);
    }



    renderCandidateMotion() {

        let ds = this.state.dataSource;
        return (
            <div className={`candidate-motion-container ${this.state.paradeMode}`}>
                <div className={`candidate-motion-label-left`}>
                    <div className={`candidate-motion-label-top-left`}>
                        <div className="cd-head _fw-li">สส.</div>
                        <div className="cd-label _fw-bd">แบบแบ่งเขต</div>
                        <div className="cd-summary _fs-40 _fw-med">350 คน</div>
                    </div>
                    <div className={`candidate-motion-label-bottom-left`}>
                        <div className="cd-head _fw-li">สส.</div>
                        <div className="cd-label _fw-bd">บัญชีรายชื่อ</div>
                        <div className="cd-summary _fs-40 _fw-med">150 คน</div>
                    </div>
                </div>
                <div className={`candidate-motion-label-merge`}>
                    <div className="cd-merge-head text-center _fw-li _fs-42"><span className="_fw-bd">ที่นั่ง</span>ในสภาฯ</div>
                    <div className="cd-summary text-center _fw-med _fs-16">500 ที่นั่ง</div>
                </div>
                <div className={`candidate-motion-wrapper`}>
                    <CandidateMatrix
                        hub={this.hub}
                        forceUpdate={this.state.forceUpdate}
                        paradeMode={this.state.paradeMode}
                        dataSource={ds}
                        dataSourceCode={this.state.dataSourceCode}
                        Party={Party} animate={this.animate}
                        onCandidateUnitPoseComplete={this.onCandidateUnitPoseComplete}
                        onCandidateUnitClick={this.onLocationSelected}
                        selectedParty={this.state.selectedParty}
                    />
                </div>
            </div>
        )
    }

    onPartyBoxClick(party) {
        let selectedParty = JSON.parse(JSON.stringify(this.state.selectedParty));
        selectedParty[party.key] = !selectedParty[party.key];
        if (!selectedParty[party.key]) {
            delete(selectedParty[party.key]);
        }
        this.setState({selectedParty: selectedParty});
    }

    partyBoxItem(party, i) {

        if (!party) {
            party = {
                shortName: '...',
                color: null,
                allTotal: '...',
                zoneTotal: '...',
                finalMoreTotal: '...'
            }
        }
        return (
            <div key={`party-box-item-${i}`}>
                <div onClick={() => {this.onPartyBoxClick(party)}}
                    className={`party-box-item party d-flex flex-column justify-content-between align-items-strech text-center ml-2 ${this.state.selectedParty[party.key]? 'active': ''}`}>
                    <div className="party-name d-flex justify-content-center flex-column" style={{backgroundColor: party.color}}>
                        <div className="text-white _fw-bd">{party.shortName || party.key.replace('พรรค', '')}</div>
                    </div>
                    <div className="quota-seat d-flex justify-content-center flex-column">
                        <div className="mb-0">{party.allTotal}</div>
                    </div>
                    <div className="quota-type d-flex flex-row justify-content-center align-items-center bg-black text-white">
                        <div className="area">{party.zoneTotal}</div>
                        <span className="mx-1">|</span>
                        <div className="party-list ">{party.finalMoreTotal}</div>
                    </div>
                </div>
            </div>
        )
    }

    partyModalBoxItem(party, i, max) {

        let percent = 0;

        if (party) {
            percent = Math.round(party.allTotal/max*100)
        } else {
            party = {
                key: '...',
                shortName: '...',
                color: null,
                allTotal: '...',
                zoneTotal: '...',
                finalMoreTotal: '...',
                score: '...'
            }
        }
        return (
            <div key={`party-modal-box-item-${i}`} className="list-group-item party-modal-box-item -party d-flex flex-row justify-content-between align-items-center w-100 rounded-0 border-0 px-3">
                <div className="left w-60 pr-3">
                    <div className="party-name d-flex justify-content-center flex-column _fw-bd _fs-24">
                        <div className="text-white">{party.key.replace('พรรค', '')}</div>
                    </div>
                    <div className="bar mb-1">
                        <div className="progress bg-trans rounded-0">
                            <div className="progress-bar" role="progressbar" style={{width: percent + '%', backgroundColor: party.color}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100" />
                        </div>
                    </div>
                    <div className="_fw-li _fs-16">{numberWithCommas(party.score)} คะแนน</div>
                </div>
                <div className="right d-flex flex-column text-right">
                    <div className="quota-seat d-flex justify-content-center flex-column">
                        <div className="mb-0 _fw-med _fs-42 _fs-30-sm _lh-80">{party.allTotal}</div>
                    </div>
                    <div className="vote-count text-white">
                        <div className="_fw-li _fs-20">{numberWithCommas(party.zoneTotal)} + {numberWithCommas(party.finalMoreTotal)}</div>
                    </div>
                </div>
            </div>
        )
    }

    candidateTableModal() {
        let max = this.state.partyMPList.reduce((a, b) => b && b.allTotal > a ? b.allTotal: a , 0);

        let sourceLabel;
        if (this.state.dataSourceCode === 'ect100') {
            sourceLabel = this.ect100Label;
        }
        else if (this.state.dataSourceCode === 'ect') {
            sourceLabel = this.ectLabel;
        } else {
            sourceLabel = (<span>* ข้อมูลจาก crowdsourcing<br />ไม่ใช่ผลการเลือกตั้งอย่างเป็นทางการ</span>)
        }

        return (
            <div className="candidate-table modal fade" id="ranking-modal" tabIndex="-1" role="dialog" aria-labelledby="rankingModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-full" role="document">
                    <div className="modal-content light-grey-bg text-white border-0 rounded-0 h-100">
                        <div className="container d-flex flex-column h-100">
                            <div className="modal-header d-block border-bottom-0">
                                <div className="d-flex flex-column align-items-start pb-2">
                                    <div className="d-flex flex-row justify-content-between w-100 mb-2">
                                        <button type="button" className="close text-white _opa-100 ml-0 mr-auto pl-0 mb-0" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true" className="_fw-reg _fs-20"><img src="/static/images/ic-close.png" srcSet="/static/images/ic-close.png 1x, /static/images/ic-close@2x.png 2x" className="mr-2" /> ปิด</span>
                                        </button>
                                        <div className="remark text-right _fs-18 _fw-reg">
                                            {sourceLabel}
                                        </div>
                                    </div>
                                    <div className="party-modal-box-item -party d-flex flex-row justify-content-between align-items-center w-100 _fw-bd bg-trans">
                                        <div className="left w-100 pr-3 _fs-16 _fw-med">พรรค</div>
                                        <div className="right d-flex flex-column text-right _fs-16 _fw-med">
                                            <div className="">ที่นั่ง</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body overflow-y-scroll pt-0">
                                <div className="list-group row">
                                    {this.state.partyMPList.map((party, i) => this.partyModalBoxItem(party, i, max))}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }    

    districtModalBoxItem(party, i, max) {
        let percent = 0;
        if (party) {
            percent = Math.round(party.total/max*100)
        }

        return (
            <div key={`party-modal-box-item-${i}`} className="list-group-item party-modal-box-item -district d-flex flex-column justify-content-between w-100 mb-0 rounded-0 border-0 px-3">
                <div className="w-100">
                    <div className="d-flex align-items-center flex-row justify-content-start mb-2">
                        {party.candidate ?
                            <div className="text-white text-center border border-color-white bg-transparent candidate-number _fs-30 _fw-med mr-2">
                                {party.candidate.no}
                            </div>
                            : ''
                        }
                        <div className="_fw-med">
                            <div className="party-name d-flex justify-content-center flex-column _fs-20">
                                <div className="text-white">{party.candidateName}</div>
                            </div>
                            <div className="party-name d-flex justify-content-center flex-column _fs-16">
                                {party.candidate ? <div className="text-white">{party.key}</div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="below d-flex flex-row align-items-end justify-content-between">
                    <div className="bar w-60 pb-1">
                        <div className="progress bg-trans rounded-0">
                            <div className="progress-bar" role="progressbar" style={{width: percent + '%', backgroundColor: party.color}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100" />
                        </div>
                    </div>
                    <div className="w-40 d-flex flex-column text-right">
                        <div className="score">
                            <div className="mb-0 _fs-32 _fw-med">{numberWithCommas(party.total)}</div>
                        </div>
                        <div className="vote-count text-white">
                            <div className="_fs-16 _fw-med">คะแนน</div>
                        </div>
                    </div>
                </div>
            </div>            
        )
    }

    seeAllScore() {
        this.hub.logStat(this.pageCode, 'motion-see-all-score');
    }

    shareClick() {
        this.hub.logStat(this.pageCode, 'motion-share');
    }

    close() {
        this.setState({ showModal: false });
    }

    candidateDistrictModal() {

        let candidates = [];
        let location = this.state.location;
        let max = 0;

        let zone;
        if (location && this.state.dataSource) {
            if (this.state.dataSource.province[location.province] && this.state.dataSource.province[location.province].zones[location.zone]) {
                zone = this.state.dataSource.province[location.province].zones[location.zone];

                let basecCandidates = this.hub.candidates.filter(i => i.province === location.province && parseInt(i.zone) === parseInt(location.zone));

                basecCandidates = basecCandidates.reduce((obj, candidate) => {obj[candidate.party] = candidate; return obj;}, {});


                zone.party.forEach(p => {

                    let partyName = p.key.replace('พรรค', '');
                    p.color = Party[partyName].color;
                    p.shortName = Party[partyName].shortName;
                    p.candidateName = Party[partyName].label || Party[partyName].shortName;

                    let c = basecCandidates['พรรค' + partyName];
                    if (c) {
                        p.candidate = c;
                        p.candidateName = `${c.prefix}${c.firstname} ${c.lastname}`;
                    }

                });
                candidates = zone.party;
                max = candidates.reduce((a, b) => b && b.total > a ? b.total: a , 0);
            }
        }

        if (!location) {
            location = {province: '...', zone: '...'};
        }

        const candidatesOnly = candidates.filter(it => ['voided', 'noVote'].indexOf(it.key) === -1)
        const noVote = candidates.find(it => it.key === 'noVote') || {total: 0}
        const voided = candidates.find(it => it.key === 'voided') || {total: 0}

        let sourceLabel;
        if (this.state.dataSourceCode === 'ect100') {
            sourceLabel = this.ect100Label;
        }
        else if (this.state.dataSourceCode === 'ect') {
            sourceLabel = this.ectLabel;
        } else {
            sourceLabel = (<span>* ข้อมูลจาก crowdsourcing<br />ไม่ใช่ผลการเลือกตั้งอย่างเป็นทางการ</span>)
        }

        return (
            <div ref="candidateDistrictModal" className="candidate-district-modal modal fade pr-0" id="candidateDistrictModal" tabIndex="-1" role="dialog" aria-labelledby="candidateDistrictModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-full modal-dialog-centered" role="document">
                    <div className="modal-content light-grey-bg _op-30 text-white border-0 rounded-0 h-100">
                        <div className="container d-flex flex-column h-100">
                            <div className="modal-header d-block border-bottom-0 pb-3 bg-black">
                                <div className="d-flex flex-column align-items-start">
                                    <div className="d-flex flex-row justify-content-between w-100 mb-2">
                                        <button type="button" className="close text-white _opa-100 ml-0 mr-auto pr-0 mb-0" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true" className="_fw-reg _fs-20"><img src="/static/images/ic-close.png" srcSet="/static/images/ic-close.png 1x, /static/images/ic-close@2x.png 2x" className="mr-2" /> ปิด</span>
                                        </button>
                                        <div className="remark text-right _fs-18 _fw-reg">
                                            <div className={`source-label`}>{sourceLabel}</div>
                                            {this.state.dataSourceCode === 'crowdsource' ?
                                                <div className="crowdsourcing-zone-detail-link mt-3">
                                                    <Link to={`/crowdsource/search/${location.province}/${location.zone}`} onClick={() => {$(this.refs.candidateDistrictModal).modal('hide');}} className={`btn rounded _fw-reg text-white py-1`}>
                                                        <div className="_fs-20 d-inline-block mr-1">ดูรูปจากทางบ้าน</div>
                                                        <img src="/static/images/ic-arrow-right.png"
                                                             srcSet="/static/images/ic-arrow-right.png 1x, /static/images/ic-arrow-right@2x.png 2x"
                                                             width="10px"
                                                             className="d-inline-block" />
                                                    </Link>
                                                </div>
                                                : ''
                                            }
                                        </div>

                                    </div>
                                    <div className="d-flex flex-column justify-content-between align-items-start w-100 pl-3">
                                        <div className="_fw-med _fs-18">คะแนนรวม</div>
                                        <div className="_fw-med _fs-32">จังหวัด {location.province}</div>
                                        <div className="_fw-med _fs-22 _lh-80">ส.ส. เขต {location.zone}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body overflow-y-scroll px-0 pt-3" style={{flexGrow: '1'}}>
                                <div className="list-group">
                                    {candidatesOnly.length > 0 && candidatesOnly.filter(p => p.total > 0).length >= 3 ?
                                        candidatesOnly.map((party, i) => {
                                            return this.districtModalBoxItem(party, i, max)
                                        }): <h3>! ขณะนี้ยังนับคะแนนไม่เสร็จ</h3>
                                    }
                                </div>
                            </div>
                            {/* {zone?
                                <div className="px-2 pb-3 mt-3">
                                    <div className="row">
                                        <div className="col-5 px-1 d-none d-sm-block">
                                            <div className="_fs-18 _fw-med">จำนวนผู้มาใช้สิทธิ์เลือกตั้ง</div>
                                            <div className="_fs-30 _fw-bd">{numberWithCommas(zone.total)}</div>
                                        </div>
                                        <div className="col px-1 border-left">
                                            <div className="_fs-18 _fw-med">บัตรดี</div>
                                            <div className="_fs-22 _fw-med">{numberWithCommas(zone.total - voided.total)}</div>
                                        </div>
                                        <div className="col px-1 border-left">
                                            <div className="_fs-18 _fw-med">บัตรเสีย</div>
                                            <div className="_fs-22 _fw-med">{numberWithCommas(voided.total)}</div>
                                        </div>
                                        <div className="col px-1 border-left">
                                            <div className="_fs-18 _fw-med">Vote NO</div>
                                            <div className="_fs-22 _fw-med">{numberWithCommas(noVote.total)}</div>
                                        </div>
                                    </div>
                                </div>: ''
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    gotoSource = source => {
        this.props.history.push(`/visual/${source}`)
        this.checkActiveSource(source)
    }

    render() {

        let ds = this.state.dataSource;
        let electorTotal = this.state.dataSourceCode === 'ect' ? 51205624: 51239638; // change this


        const {
            queryLocation, locationMatches
        } = this.state;

        const locationInputProps = {
            id: 'input-location',
            placeholder: 'แขวง / เขต / รหัสไปรษณีย์',
            className: 'form-control _fw-med',
            value: queryLocation,
            onChange: this.onLocationChange
        };

        let locationButton;
        if (queryLocation) {
            locationButton = (
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" onClick={this.onLocationClear} type="button"><i className="fa fa-close"> </i></button>
                </div>
            );
        } else {
            locationButton = (
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button"><img src="/static/images/ic-search.png" srcSet="/static/images/ic-search.png 1x, /static/images/ic-search@2x.png 2x" width="18px"/></button>
                </div>
            );
        }


        let totalCome = 0
        if (ds) {
            totalCome = (((ds.goodVoteTotal + ds.noVoteTotal + ds.voidedTotal) * 100)/electorTotal).toFixed(2)
        }

        // ===================================================
        // ButtonText
        // ===================================================

        let buttonText;
        if (this.state.paradeMode === 'geoPartyList') {
            buttonText = (
                <div className="btn-motion py-1 -council d-flex flex-row justify-content-end">
                    <div className="_fw-med text-right">
                        <div className="_fs-22 _lh-80">ที่นั่ง</div>
                        <div className="_fs-14">ในสภา</div>
                    </div>
                </div>
            );
        } else {
            buttonText = (
                <div className="btn-motion py-1 -geo d-flex flex-row justify-content-end">
                    <div className="_fw-med text-right">
                        <div className="_fs-22 _lh-80">สส.​</div>
                        <div className="_fs-14">แบบแบ่งเขต</div>
                    </div>
                </div>
            );
        }
        if (this.state.loading) {
            buttonText = (
                <div className="btn-motion px-0 bg-white">
                    <img src="/static/images/preloading.gif" alt="loading ..." width="auto" height="20px" />
                    <div className="_lh-80"><small>กำลังประมวลผล</small></div>
                </div>
            );
        }

        const setCSModal = ref => {
            // jQuery(ref).modal()
        }

        let sourceLabel;
        if (this.state.dataSourceCode === 'ect100') {
            sourceLabel = this.ect100Label;
        }
        else if (this.state.dataSourceCode === 'ect') {
            sourceLabel = this.ectLabel;
        } else {
            sourceLabel = (<span>* ข้อมูลจาก crowdsourcing<br />ไม่ใช่ผลการเลือกตั้งอย่างเป็นทางการ</span>)
        }

        return (
            <div className="">
                <div className="px-0 container candidate-motion-header position-relative mb-3">
                    <div className="container condidate-motion-wrapper p-2 px-sm-3 pt-sm-3 pb-sm-2">
                        <div className="d-flex flex-row align-items-center justify-content-start">
                            <div className="candidate-motion-source d-flex flex-row justify-content-start justify-content-sm-center align-items-center _fs-18 _fw-bd">
                                <div className="mr-1 mr-sm-3"><strong>ผลการนับคะแนนจาก</strong></div>
                                <button type="button" onClick={() => {this.hub.logStat(this.pageCode, 'motion-select-data-source-ect100'); this.gotoSource('')}} className={`px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'ect100'? ' btn-secondary active': ''}`}>กกต. 100%</button>
                                <button type="button" onClick={() => {this.hub.logStat(this.pageCode, 'motion-select-data-source-ect'); this.gotoSource('ect94')}} className={`px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'ect'? ' btn-secondary active': ''}`}>กกต. 94%</button>
                                <button type="button" onClick={() => {this.hub.logStat(this.pageCode, 'motion-select-data-source-crowdsource'); this.gotoSource('crowdsource')}} className={`px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'crowdsource'? ' btn-secondary active': ''}`}>ทางบ้าน</button>
                                 <button type="button" onClick={() => {this.updateDataSource('statistic')}} className={`d-none px-2 py-1 btn mr-1 mr-sm-3${this.state.dataSourceCode === 'statistic'? ' btn-secondary active': ''}`}>สถิติ</button>
                            </div>
                            {/*<div className="candidate-motion-timestamp text-right ml-auto">*/}
                                {/*<div className="_fs-13 _fw-med">นับเสร็จแล้ว</div>*/}
                                {/*<div className="cd-percent _fs-20 _lh-14 _fw-med">*/}
                                    {/*/!*{ds?(ds.succeedTotal/350*100).toFixed(2):''}*!/*/}
                                    {/*94*/}
                                    {/*<span className="_fs-13">%</span>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="position-relative mb-1" style={{overflow: 'hidden'}}>
                        <div className="position-absolute py-2">
                            <div className="party-box-item sample d-flex flex-column justify-content-between align-items-strech text-center ml-2">
                                <div className="party-name d-flex justify-content-center flex-column">
                                    <div>ชื่อพรรค</div>
                                </div>
                                <div className="quota-seat d-flex justify-content-center flex-column">
                                    <div>สส. (ที่นั่ง)</div>
                                </div>
                                <div className="quota-type d-flex flex-row justify-content-center align-items-center">
                                    <div className="area">เขต </div> <span className="mx-1">|</span> <div className="party-list "> บัญชี</div>
                                </div>
                            </div>
                        </div>
                        <div className="position-relative party-box-list py-2">

                            {this.state.partyMPList.filter(p => !p || p.allTotal > 0).map((party, i) => this.partyBoxItem(party, i))}

                            <div className="">
                                <div className="party-box-item last d-flex flex-column justify-content-center align-items-strech text-center mx-2 bg-black">
                                    <a href="#" className="_fw-reg text-white" data-toggle="modal" data-target="#ranking-modal" onClick={this.seeAllScore}>
                                        <img src="/static/images/ic-best.png" srcSet="/static/images/ic-best.png 1x, /static/images/ic-best@2x.png 2x" width="30px" className="mb-2" />
                                        <div className="_fs-13">ดูคะแนนทั้งหมด</div>
                                        <img src="/static/images/ic-arrow-right.png" srcSet="/static/images/ic-arrow-right.png 1x, /static/images/ic-arrow-right@2x.png 2x" width="15px" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="party-box-list-shadow"></div>
                    </div>

                    <div className="search-switch-wrapper mx-auto d-flex flex-row justify-content-between align-items-center">
                        <div className="search-box-wrapper text-center d-flex flex-row align-items-center">
                            <div className="input-group">
                                <Autosuggest
                                    ref="locationAutosuggest"
                                    suggestions={locationMatches}
                                    onSuggestionsFetchRequested={this.onLocationSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onLocationSuggestionsClearRequested}
                                    onSuggestionSelected={this.onLocationSuggestionSelected}
                                    getSuggestionValue={this.getLocationSuggestionValue}
                                    shouldRenderSuggestions={this.shouldRenderLocationSuggestions}
                                    renderSuggestion={this.renderLocationItem}
                                    inputProps={locationInputProps}
                                />
                                {locationButton}
                            </div>
                        </div>
                        <div className={`candidate-motion-label-middle-left mr-2`} onClick={this.toggleMatrixMode}>
                            <button className={`${this.state.loading? 'loading': ''} w-100 btn motion-shadow _fw-bd p-0 border-0`}>
                                {buttonText}
                            </button>
                        </div>
                    </div>

                </div>
                <div className="container mb-5">
                    <div className="candidate-motion-middle">
                        <div ref="mainContent" className={`candidate-motion-main-content${this.state.dataSourceCode === 'ect'? ' etc': ''}`}>
                            {this.renderCandidateMotion()}
                        </div>
                        <div className="remark text-center _fs-18 _fw-reg mt-4">
                            {sourceLabel}
                        </div>
                    </div>
                </div>

                <div className="lighter-grey-bg _mb-60">
                    <div className="container pt-4 pb-3">
                        <div className="d-flex flex-row justify-content-around justify-content-sm-between align-items-end align-items-sm-start">
                            <div className="mb-2 mb-sm-0 d-none d-sm-block">
                                <div className="_fs-16 _fw-med">จำนวนผู้มาใช้สิทธิ์คิดเป็น</div>
                                <div className="_fs-48 _lh-80 _fw-bd">{ds? `${numberWithCommas(totalCome)}`: '...'}<span className="_fw-med _fs-28">%</span></div>
                            </div>
                            <div className="d-flex flex-column flex-sm-row">
                                <div className="mb-3 mb-sm-0 d-block d-sm-none">
                                    <div className="_fs-16 _fw-med">จำนวนผู้มาใช้สิทธิ์คิดเป็น</div>
                                    <div className="_fs-48 _lh-80 _fw-bd">{ds? `${numberWithCommas(totalCome)}`: '...'}<span className="_fw-med _fs-28">%</span></div>
                                </div>
                                <div className="">
                                    <div className="mb-3 mb-sm-0">
                                        <div className="_fs-16 _fw-med">จำนวนผู้มาใช้สิทธิ์เลือกตั้ง</div>
                                        <div className="_fs-42 _lh-80 _fw-bd">{numberWithCommas(ds? Math.round((ds.goodVoteTotal + ds.noVoteTotal + ds.voidedTotal))+'': '...')}</div>
                                    </div>
                                    <div className="">
                                        <div className="_fs-16 _fw-med">จำนวนผู้มีสิทธิ์เลือกตั้ง</div>
                                        <div className="_fs-30 _lh-80 _fw-bd">{numberWithCommas(electorTotal)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-left border-dark pl-4 w-sm-25">
                                <div className="mb-3 mb-sm-0">
                                    <div className="_fs-16 _fw-med">บัตรดี</div>
                                    <div className="_fs-30 _lh-80 _fw-bd">{ds? numberWithCommas(ds.goodVoteTotal): '...'}</div>
                                </div>
                                <div className="mb-3 mb-sm-0">
                                    <div className="_fs-16 _fw-med">บัตรเสีย</div>
                                    <div className="_fs-30 _lh-80 _fw-bd">{ds? numberWithCommas(ds.voidedTotal): '...'}</div>
                                </div>
                                <div className="mb-3 mb-sm-0">
                                    <div className="_fs-16 _fw-med">Vote No</div>
                                    <div className="_fs-30 _lh-80 _fw-bd">{ds? numberWithCommas(ds.noVoteTotal): '...'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="candidate-motion-summary d-flex flex-row justify-content-between align-items-center light-grey-bg">
                    <a href="#" data-toggle="modal" data-target="#ranking-modal" className="text-white d-flex align-items-center" onClick={this.seeAllScore}>
                        <img src="/static/images/ic-best.png" srcSet="/static/images/ic-best@2x.png 2x" width="26px" className="mr-2" /> <span className="_fs-20 _fw-med">ดูคะแนนทั้งหมด</span>
                    </a>

                    <a href="#" data-toggle="modal" data-target="#send-modal" className="btn border text-white d-flex align-items-center" onClick={this.shareClick}>
                        <i className="fa fa-send-o fa-1 text-white" aria-hidden="true"></i>&nbsp;&nbsp;
                        <span className="_fs-20 _fw-med text-white">แชร์</span>
                    </a>

                </div>

                {this.candidateTableModal()}
                
                {this.candidateDistrictModal()}

                {this.state.preparing && !this.state.forceUpdate?
                    <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                        <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                            <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                                <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px', marginBottom: '1rem'}}/>
                                <h4 className="_fw-bd _fs-20">กำลังประมวลผลรายงาน</h4>
                            </div>
                        </div>
                    </div>: ''
                }

                <div className="modal fade vertically-modal" id="send-modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-sm modal-send" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                    แชร์ให้เพื่อน &nbsp;&nbsp;
                                    <small><small><i className="fa fa-send-o fa-1" aria-hidden="true"></i></small></small>
                                </h4>
                                <button type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body text-left">
                                <div className="share-buttons">
                                    <span>
                                        <LineShareButton url={window.location.href}><LineIcon/></LineShareButton>
                                    </span>
                                    <span>
                                        <FacebookShareButton url={window.location.href}><FacebookIcon/></FacebookShareButton>
                                    </span>
                                    <span>
                                        <TwitterShareButton url={window.location.href}><TwitterIcon/></TwitterShareButton>
                                    </span>
                                    <span>
                                        <EmailShareButton url={window.location.href}><EmailIcon/></EmailShareButton>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="modal fade modal-dialog-centered" ref={setCSModal} id="crowdsourcing-modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block border-bottom-0 pb-3 bg-black">
                                <button type="button" className="close text-white _opa-100 ml-0 mr-auto pr-0 mb-0" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" className="_fw-reg _fs-20"><img src="/static/images/ic-close.png" srcSet="/static/images/ic-close.png 1x, /static/images/ic-close@2x.png 2x" className="mr-2" /> ปิด</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="wrapper-height100">
                                    <div className="row justify-content-md-center">
                                        <div className="col-12">
                                            <div className="card py-5 px-4">
                                                <h2>มาช่วยกัน<br />รายงานผลเลือกตั้ง</h2>
                                                <p className="lead">ถ่ายรูปกระดานนับคะแนนเลือกตั้งที่นับเสร็จแล้ว จากหน่วยเลือกตั้งใกล้บ้าน <i>หรือ</i> ช่วยกรอกตัวเลขจากรูปภาพผลคะแนนที่มีคนส่งเข้ามา</p>

                                                <h3 className="mt-5">มาลองกันเลย!</h3>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="/crowdsource/upload" className="text-white d-block border rounded text-center px-3 py-2 bg-dark h-100">
                                                            <img src="/static/images/ic-report@2x.png" className="img-fluid" alt="อัปโหลดภาพ" /><br />
                                                            อัปโหลดภาพ
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link to="/crowdsource/digitize" className="text-white d-block border rounded text-center px-3 py-2 bg-dark h-100">
                                                            <img src="/static/images/ic-send-result@2x.png" className="img-fluid" alt="กรอกคะแนน" /><br />
                                                            กรอกคะแนน
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        )
    }
}