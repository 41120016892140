import React from 'react'
import PageComponent from './PageComponent.jsx'
import * as axios from 'axios'

import Province from "../data/provinces.json";
import Party from "../data/party.json";

export default class CrowdSourcingOverview extends PageComponent {
    constructor(props) {
        super(props);
        this.hub = props.hub;

        this.pageCode = 'crowdsource-overview';
        this.state = {
            loading: true,
            zoneUnits: [],
            voteResults: null,
        };


        const parties = new Set()

        let candidatesMap = {};

        this.hub.candidates.forEach(c => {
            let province = candidatesMap[c.province] = candidatesMap[c.province] || {zones: {}};
            let zone = province.zones[c.zone] = province.zones[c.zone] || {candidatesMap: {}, candidates: []};

            zone.candidatesMap[c.no] = c;
            zone.candidates.push(c);

            parties.add(c.party)
        });
        this.candidatesMap = candidatesMap;

        this.processData = this.processData.bind(this);

    }

    componentDidMount() {
        this.processData();
    }

    async processData() {

        const res = await axios.get(`https://firebasestorage.googleapis.com/v0/b/thvote62.appspot.com/o/data%2Fzone.unit.json?alt=media`);

        this.prevProvinceId = null;
        let zoneUnits = res.data.items;
        this.setState({
            zoneUnits: zoneUnits
        });

        const url = `https://us-central1-${this.hub.config.projectId}.cloudfunctions.net`;

        let voteResults = await axios.get(`${url}/cleanedVoteResults`);
        voteResults = voteResults.data;

        let self = this;

        Object.keys(voteResults).map(function(provinceKey) {
            let province = voteResults[provinceKey];
            // console.log('province', provinceKey, province);

            Object.keys(province).map(function(zoneKey) {
                let zone = province[zoneKey];
                // console.log('zone', zone);

                Object.keys(zone).map(function(unitKey) {
                    let unit = zone[unitKey];
                    // console.log('unit', unit);

                    let cadidates = [];

                    Object.keys(unit).map(function(noKey) {

                        let total = unit[noKey] || 0;
                        // console.log(zoneKey, self.candidatesMap[provinceKey])

                        let party;

                        try {
                            party = self.candidatesMap[provinceKey].zones[zoneKey].candidatesMap[noKey];
                            party.color = Party[party.party.replace('พรรค', '')].color;

                        } catch (e) {

                        }
                        // party.color = Party[party]

                        cadidates.push({
                            no: noKey,
                            total: total,
                            party: party
                        });
                    });
                    cadidates.sort((a, b) => (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0));

                    zone[unitKey] = {
                        cadidates: cadidates,
                        color: cadidates[0] && cadidates[0].party ? cadidates[0].party.color: null
                    };


                });

            });

        });

        this.setState({
            voteResults: voteResults
        });

        this.setState({
            loading: false
        })

    };

    renderZone = (zone, index, colPerRow, k) => {
        const Aux = props => props.children;

        const renderProvince = parseInt(zone.provinceId) !== parseInt(this.prevProvinceId);
        this.prevProvinceId = zone.provinceId;



        return (
            <Aux key={index}>
                <tr>
                    <td rowSpan={1*k+1} colSpan={1*k}>{renderProvince ? Province[zone.provinceId]: ''}</td>
                    <td rowSpan={1*k+1} colSpan={1*k}>{zone.no}</td>
                </tr>

                {[...Array(k)].map((tr, j) =>
                <tr key={j}>
                    {[...Array(colPerRow)].map((td, i) => {
                        let unitNo = (j * colPerRow) + (i + 1);
                        let unit;
                        let style;

                        if (this.state.voteResults) {

                            let province = Province[zone.provinceId];
                            province = this.state.voteResults[province];
                            if (province && province[zone.no]) {
                                unit = province[zone.no][unitNo];
                                if (unit) {
                                    style = {backgroundColor: unit.color}
                                }
                            }
                        }


                        return <td key={unitNo} className={unitNo <= zone.units? `unit`: ''} style={style}></td>
                    })}
                </tr>
                )}

                <tr><td colSpan={3*k + colPerRow} className={`splitter`}></td></tr>
            </Aux>
        )
    };

    render() {
        let maxUnits = 474;
        let colPerRow = 100;
        let totalUnits = 500;
        let k = totalUnits/colPerRow;

        return (
            <div>
                <table className="table table-bordered table-dark crow-overview">
                    <thead>
                        <tr>
                            <th scope="col" rowSpan={2} colSpan={1*k}>จังหวัด</th>
                            <th scope="col" rowSpan={2} colSpan={1*k}>เขต</th>
                            <th scope="col" rowSpan={1} colSpan={colPerRow*k}>หน่วย</th>
                        </tr>
                        <tr className={`tr-dummy`}>
                            {[...Array(colPerRow)].map((e, i) => <th rowSpan={1} colSpan={1} key={i}></th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.zoneUnits.map((zone, index) => this.renderZone(zone, index, colPerRow, k))}
                    </tbody>
                </table>

                {this.state.loading?
                    <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                        <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                            <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                                <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px', marginBottom: '1rem'}}/>
                                <h4 className="_fw-bd _fs-20">กำลังประมวลผลรายงาน</h4>
                            </div>
                        </div>
                    </div>: ''
                }
            </div>
        )

    }

}